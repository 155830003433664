<script>
import { authComputed } from "@/state/helpers";
import { maxLength, email, required } from "vuelidate/lib/validators";

export default {
  name: "applicantDataStep",
  components: {},
  data() {
    return {
      loggedUser: null,
      applicantForm: {
        typeOfApplicationName: "Przelew krajowy",
        applicantUserAccountId: "",
        applicantFirstNameField: "",
        applicantLastNameField: "",
        applicantEmailField: "",
      },
    };
  },
  created() {
    this.loggedUser = this.currentUser();
    this.applicantForm.applicantUserAccountId = this.loggedUser.userAccountId;
    this.applicantForm.applicantFirstNameField = this.loggedUser.firstName;
    this.applicantForm.applicantLastNameField = this.loggedUser.lastName;
    this.applicantForm.applicantEmailField = this.loggedUser.email;
  },
  validations: {
    applicantForm: {
      applicantFirstNameField: {
        required,
        maxLength: maxLength(100),
      },
      applicantLastNameField: {
        required,
        maxLength: maxLength(100),
      },
      applicantEmailField: {
        required,
        email,
        maxLength: maxLength(255),
      },
    },
  },
  methods: {
    ...authComputed,
    validate() {
      this.$v.applicantForm.$touch();
      var isValid = !this.$v.applicantForm.$invalid;
      this.$emit("on-validate", this.$data.applicantForm, isValid);
      return isValid;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label for="applicantFirstNameField">
          Imię
          <em class="text-danger">*</em>
        </label>
        <input id="applicantFirstNameField" v-model.trim="applicantForm.applicantFirstNameField" @input="$v.applicantForm.applicantFirstNameField.$touch()" class="form-control" :class="{ 'is-invalid': $v.applicantForm.applicantFirstNameField.$error, 'is-valid': !$v.applicantForm.applicantFirstNameField.$invalid && !$v.applicantForm.applicantFirstNameField.$error }" type="text" name="applicantFirstNameField" placeholder="Wpisz swoje imię" value required disabled />
        <div v-if="$v.applicantForm.applicantFirstNameField.$error" class="invalid-feedback">
          <span class="d-block" v-if="$v.applicantForm.applicantFirstNameField.$error && !$v.applicantForm.applicantFirstNameField.required">Pole jest wymagane!</span>
          <span class="d-block" v-if="$v.applicantForm.applicantFirstNameField.$error && !$v.applicantForm.applicantFirstNameField.maxLength">Przekroczona dozwolona ilość znaków!</span>
        </div>
      </div>
      <div class="form-group">
        <label for="applicantLastNameField">
          Nazwisko
          <em class="text-danger">*</em>
        </label>
        <input id="applicantLastNameField" v-model.trim="applicantForm.applicantLastNameField" @input="$v.applicantForm.applicantLastNameField.$touch()" class="form-control" :class="{ 'is-invalid': $v.applicantForm.applicantLastNameField.$error, 'is-valid': !$v.applicantForm.applicantLastNameField.$invalid && !$v.applicantForm.applicantLastNameField.$error }" type="text" name="applicantLastNameField" placeholder="Wpisz swoje nazwisko" value required disabled />
        <div v-if="$v.applicantForm.applicantLastNameField.$error" class="invalid-feedback">
          <span class="d-block" v-if="$v.applicantForm.applicantLastNameField.$error && !$v.applicantForm.applicantLastNameField.required">Pole jest wymagane!</span>
          <span class="d-block" v-if="$v.applicantForm.applicantLastNameField.$error && !$v.applicantForm.applicantLastNameField.maxLength">Przekroczona dozwolona ilość znaków!</span>
        </div>
      </div>
      <div class="form-group">
        <label for="applicantEmailField">
          Adres E-mail
          <em class="text-danger">*</em>
        </label>
        <input id="applicantEmailField" v-model.trim="applicantForm.applicantEmailField" @input="$v.applicantForm.applicantEmailField.$touch()" class="form-control" :class="{ 'is-invalid': $v.applicantForm.applicantEmailField.$error, 'is-valid': !$v.applicantForm.applicantEmailField.$invalid && !$v.applicantForm.applicantEmailField.$error }" type="email" name="applicantEmailField" placeholder="Wpisz adres e-mail" value required disabled />
        <div v-if="$v.applicantForm.applicantEmailField.$error" class="invalid-feedback">
          <span class="d-block" v-if="$v.applicantForm.applicantEmailField.$error && !$v.applicantForm.applicantEmailField.required">Adres E-mail jest wymagany!</span>
          <span class="d-block" v-if="$v.applicantForm.applicantEmailField.$error && !$v.applicantForm.applicantEmailField.email">To nie jest prawidłowy adres email!</span>
          <span class="d-block" v-if="$v.applicantForm.applicantEmailField.$error && !$v.applicantForm.applicantEmailField.maxLength">Przekroczona dozwolona ilość znaków!</span>
        </div>
      </div>
    </div>
  </div>
</template>
