<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import Switches from "vue-switches";
import {
  required,
  maxLength,
  minLength,
  numeric,
} from "vuelidate/lib/validators";
import InputTaxNumber from "@/components/form-elements/input-tax-number"
import AddressFormGroup from "@/components/form-elements/address-form-group";
import Repository from "@/app/repository/repository-factory";

const ProviderRepository = Repository.get("ProviderRepository");

export default {
  name: "providerDataStep",
  props: {
    transferType: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
    clonedData: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
    bucAsUuid: {
      type: String,
      required: true,
    },
  },
  components: {
    Multiselect,
    Switches,
    InputTaxNumber,
    AddressFormGroup,
  },
  data() {
    return {
      inputLoading: false,
      requestErrorObj: null,
      providerSelectOptionsField: [],
      disabledSwitch: 0,
      providerSelectValueField: null,
      providerSearchValueField: null,
      providerSearchedInRepository: false,
      providerForm: {
        providerSapId: "",
        providerNameField: "",
        providerAddressField: "",
        providerAddressCity: "",
        providerAddressStreet: "",
        providerAddressHouseNo: "",
        providerAddressFlatNo: "",
        providerAddressPostalCode: "",
        providerAddressInternational: "",
        providerTaxNumberField: null,
        providerAccountField: "",
        providerFormDataModified: false,
        providerWithoutTaxNumber: true,
      },
      switchWasClicked: false,
    };
  },
  validations: {
    providerForm: {
      providerNameField: {
        required,
        maxLength: maxLength(255),
        minLength: minLength(3),
      },
      // providerAddressField: {
      //   required,
      //   maxLength: maxLength(255),
      //   minLength: minLength(3),
      // },
      providerAccountField: {
        required,
        maxLength: maxLength(26),
        minLength: minLength(26),
        numeric,
      },
    },
  },
  created() {
    if (this.clonedData !== null) {
      let provider = {
        idProvider: this.clonedData.providerSapId,
        name: this.clonedData.providerName,
        // address: this.clonedData.providerAddress,
        international: this.clonedData.applicationSpecificData.providerAddress.international,
        street: this.clonedData.applicationSpecificData.providerAddress.street,
        houseNo: this.clonedData.applicationSpecificData.providerAddress.houseNo,
        flatNo: this.clonedData.applicationSpecificData.providerAddress.flatNo,
        postalCode: this.clonedData.applicationSpecificData.providerAddress.postalCode,
        city: this.clonedData.applicationSpecificData.providerAddress.city,

        nip: this.clonedData.providerTaxNumber,
        provWithoutTax: true,
        bankAccount: this.clonedData.providerBankAccountNumber,
        modified: this.clonedData.changeDataManually,
      };

      this.dispatchAction(provider);
    }
  },
  methods: {
    validate() {
      this.$v.providerForm.$touch();
      this.$refs.InputTaxNumber.$v.$touch();
      this.$refs.AddressFormGroup.$v.$touch();
      var isValid = !this.$v.providerForm.$invalid && !this.$refs.InputTaxNumber.$v.$invalid && !this.$refs.AddressFormGroup.$v.$invalid && (this.providerForm.providerFormDataModified == this.switchWasClicked);
      this.$emit("on-validate", this.$data.providerForm, isValid);
      return isValid;
    },
    dispatchAction(el) {
      this.providerForm.providerSapId = el.idProvider;
      this.providerForm.providerNameField = el.name;
      // if (el.address) {
      //   this.providerForm.providerAddressField = el.address;
      // } else {
      //   this.providerForm.providerAddressField =
      //     el.street + ", " + el.postCode + " " + el.city;
      // }
      this.providerForm.providerAddressCity = el.city;
      this.providerForm.providerAddressStreet = el.street;
      this.providerForm.providerAddressHouseNo = el.houseNo;
      this.providerForm.providerAddressFlatNo = el.flatNo;
      this.providerForm.providerAddressPostalCode = el.postalCode;
      this.providerForm.providerAddressInternational = el.international;

      this.providerForm.providerTaxNumberField = el.nip;
      this.providerForm.providerAccountField = el.bankAccount;
      this.disabledSwitch = this.clonedData !== null && el.modified == 1 ? 1 : 0;
      this.providerForm.providerWithoutTaxNumber = true;
      this.providerForm.providerFormDataModified = this.clonedData !== null && el.modified == 1 ? true : false;
    },
    clearForm() {
      this.providerForm.providerSapId = "";
      this.providerForm.providerNameField = "";
      // this.providerForm.providerAddressField = "";
      this.providerForm.providerAddressCity = "";
      this.providerForm.providerAddressStreet = "";
      this.providerForm.providerAddressHouseNo = "";
      this.providerForm.providerAddressFlatNo = "";
      this.providerForm.providerAddressPostalCode = "";
      this.providerForm.providerAddressInternational = "";

      this.providerForm.providerTaxNumberField = null;
      this.providerForm.providerAccountField = "";
      this.providerForm.providerWithoutTaxNumber = true;
      this.providerSelectValueField = [];
      this.switchWasClicked = false;
    },
    providerLabel({ name, nip, address, bankAccount }) {
      return `${name} ${nip} ${address} ${bankAccount}`;
    },
    onChangeEventHandler(event) {
      this.disabledSwitch = event ? 1 : 0;
      if (this.disabledSwitch === 1 && this.clonedData == null) {
        Vue.swal({
          title: "Uwaga!",
          html:
            "Ręczna zmiana danych dostawcy wymaga podwójnej akceptacji.<br/><br/><small>1. Akceptujący wyższego rzędu.<br/>2. Akceptujący w spółce.<br/><br/>" +
            "<span class='text-danger'>Wnioskujący zobowiązany jest zgłosić zmianę danych w systemie źródłowym!</span><br/><br/>" +
            "Zgłoszenie zmiany danych w systemie źródłowym <strong>SAP</strong> należy wysłać na adres e-mail:</small><br/>dane.podstawowemm@si-consulting.pl<br/><br/><small>Zgłoszenie zmiany danych w systemie źródłowym <strong>Impuls</strong> należy wysłać na adres e-mail:</small><br/>typeemailhere</small><br/>",
          imageUrl: require("@/assets/images/si-pay-logo.png"),
          imageHeight: 30,
          confirmButtonColor: "#556ee6",
        });
      }
      if (this.disabledSwitch === 1) {
        this.switchWasClicked = true;
      }
    },
    searchProvider() {
      if (
        this.providerSearchValueField !== null &&
        this.providerSearchValueField !== ""
      ) {
        this.providerSearchedInRepository = false;
        this.requestErrorObj = null;
        this.inputLoading = true;
        ProviderRepository.search(this.providerSearchValueField, this.bucAsUuid)
          .then((response) => {
            this.providerSelectOptionsField = response.data;
            this.providerSearchedInRepository = true;
            this.inputLoading = false;
            this.clearForm();
          })
          .catch((error) => {
            this.providerSearchedInRepository = false;
            this.inputLoading = false;
            this.requestErrorObj = error;
            console.log(error);
          });
      }
    },
    getNipValue: function(params) {
      this.providerForm.providerTaxNumberField = params;
    },
    getProviderAddressCityFormValues: function (params) {
      this.providerForm.providerAddressCity = params;
    },
    getProviderAddressStreetFormValues: function (params) {
      this.providerForm.providerAddressStreet = params;
    },
    getProviderAddressHouseNoFormValues: function (params) {
      this.providerForm.providerAddressHouseNo = params;
    },
    getProviderAddressFlatNoFormValues: function (params) {
      this.providerForm.providerAddressFlatNo = params;
    },
    getProviderAddressPostalCodeFormValues: function (params) {
      this.providerForm.providerAddressPostalCode = params;
    },
    getProviderAddressInternationalFormValues: function (params) {
      this.providerForm.providerAddressInternational = params;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="form-row">
        <div class="form-group col-md-10">
          <label for="providerSearchValueField">Wyszukaj dostawcę w bazie danych</label>
          <input
            id="providerSearchValueField"
            v-model="providerSearchValueField"
            class="form-control"
            type="text"
            name="providerSearchValueField"
            placeholder="Wyszukaj dostawcę po numerze NIP lub identyfikatorze ID"
            value
            required
            v-on:keyup.enter="searchProvider"
            :disabled="inputLoading"
          />
        </div>
        <div class="form-group col-md-2 align-self-end text-right">
          <button class="btn btn-info" @click="searchProvider">
            <i v-if="inputLoading" class="el-icon-loading mr-1"></i>
            <i v-else class="el-icon-search mr-1"></i>
            Szukaj
          </button>
        </div>
      </div>
      <b-alert variant="info" class="mb-3" show fade v-if="providerSearchedInRepository && providerSelectOptionsField.length == 0">
        <i class="mdi mdi-shield-alert-outline mr-2"></i><strong>Uwaga!</strong> Nie znaleziono dostawcy w bazie danych!
        <br /><small>Wprowadź poprawne dane, wyszukaj innego dostawcę lub zgłoś potrzebę założenia Dostawcy w systemie rozliczeniowym (SAP/Impuls).</small>
      </b-alert>
      <b-alert variant="danger" class="mb-3" show fade v-if="requestErrorObj">
        <i class="mdi mdi-shield-alert-outline mr-2"></i><strong>Uwaga!</strong> Wystąpił błąd!
        <br /><small>{{ requestErrorObj.response.data.message }}</small>
      </b-alert>
      <hr />
      <div class="form-group alert alert-success" v-if="providerSearchedInRepository && providerSelectOptionsField.length > 0">
        <label for="providerSelectValueField">Wybierz rachunek dostawcy</label>
        <multiselect id="providerSelectValueField"
          v-model.trim="providerSelectValueField"
          :options="providerSelectOptionsField"
          @select="dispatchAction"
          :custom-label="providerLabel"
          track-by="bankAccount"
          name="providerSelectValueField"
          placeholder="Wybierz rachunek dostawcy z listy"
          select-label
          deselect-label="Odznacz ✕"
          selected-label="Wybrane ✓"
          value
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option.name }}<br/><small class="text-muted">{{ props.option.bankAccount }}</small></span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.name }}</span>
              <br />
              <span class="option__title">Numer rachunku: {{ props.option.bankAccount }}</span>
              <br />
              <template v-if="props.option.idProvider !== ''">
                <span class="option__small small text-dark">ID Dostawcy: {{ props.option.idProvider }}</span>
                <br />
              </template>
              <template v-if="props.option.nip !== ''">
                <span class="option__small small text-dark">Nip: {{ props.option.nip }}</span>
                <br />
              </template>
              <span class="option__small small text-dark">Adres: {{ props.option.street }}, {{ props.option.postCode }} {{ props.option.city }}</span>
            </div>
          </template>
          <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
        </multiselect>
      </div>
      <div class="form-group">
        <label for="providerNameField">
          Nazwa dostawcy
          <em class="text-danger">*</em>
        </label>
        <input id="providerNameField" v-model.trim="providerForm.providerNameField" @input="$v.providerForm.providerNameField.$touch()" class="form-control" :class="{ 'is-invalid': $v.providerForm.providerNameField.$error, 'is-valid': !$v.providerForm.providerNameField.$invalid && !$v.providerForm.providerNameField.$error }" type="text" name="providerNameField" placeholder="Wpisz nazwę firmy" value required :disabled="disabledSwitch == 0" />
        <div v-if="$v.providerForm.providerNameField.$error" class="invalid-feedback">
          <span class="d-block" v-if="$v.providerForm.providerNameField.$error && !$v.providerForm.providerNameField.required">Pole jest wymagane!</span>
          <span class="d-block" v-if="$v.providerForm.providerNameField.$error && !$v.providerForm.providerNameField.maxLength">Przekroczona dozwolona ilość znaków!</span>
          <span class="d-block" v-if="$v.providerForm.providerNameField.$error && !$v.providerForm.providerNameField.minLength">Zbyt mała ilość znaków!</span>
        </div>
      </div>

      <AddressFormGroup
      @address-city-form-group="getProviderAddressCityFormValues"
      @address-street-form-group="getProviderAddressStreetFormValues"
      @address-house-no-form-group="getProviderAddressHouseNoFormValues"
      @address-flat-no-form-group="getProviderAddressFlatNoFormValues"
      @address-postal-code-form-group="getProviderAddressPostalCodeFormValues"
      @address-international-form-group="getProviderAddressInternationalFormValues"
      :incomingAddressCityValue="providerForm.providerAddressCity"
      :incomingAddressStreetValue="providerForm.providerAddressStreet"
      :incomingAddressHouseNoValue="providerForm.providerAddressHouseNo"
      :incomingAddressFlatNoValue="providerForm.providerAddressFlatNo"
      :incomingAddressPostalCodeValue="providerForm.providerAddressPostalCode"
      :incomingAddressInternationalValue="providerForm.providerAddressInternational"
      :disable="disabledSwitch"
      name="AddressFormGroup"
      ref="AddressFormGroup" />

      <!-- <div class="form-group">
        <label for="providerAddressField">
          Adres dostawcy
          <em class="text-danger">*</em>
        </label>
        <input id="providerAddressField" v-model.trim="providerForm.providerAddressField" @input="$v.providerForm.providerAddressField.$touch()" class="form-control" :class="{ 'is-invalid': $v.providerForm.providerAddressField.$error, 'is-valid': !$v.providerForm.providerAddressField.$invalid && !$v.providerForm.providerAddressField.$error }" type="text" name="providerAddressField" placeholder="Wpisz adres firmy" value required :disabled="disabledSwitch == 0" />
        <div v-if="$v.providerForm.providerAddressField.$error" class="invalid-feedback">
          <span class="d-block" v-if="$v.providerForm.providerAddressField.$error && !$v.providerForm.providerAddressField.required">Pole jest wymagane!</span>
          <span class="d-block" v-if="$v.providerForm.providerAddressField.$error && !$v.providerForm.providerAddressField.maxLength">Przekroczona dozwolona ilość znaków!</span>
          <span class="d-block" v-if="$v.providerForm.providerAddressField.$error && !$v.providerForm.providerAddressField.minLength">Zbyt mała ilość znaków!</span>
        </div>
      </div> -->
      <InputTaxNumber
        @input-tax-number="getNipValue"
        :incomingValue="providerForm.providerTaxNumberField"
        :inputLabel="'NIP dostawcy'"
        :disable="disabledSwitch"
        :withoutTaxNumber="transferType && transferType.index == 1"
        ref="InputTaxNumber"
      />
      <div class="form-group">
        <label for="providerAccountField">
          Numer konta dostawcy
          <em class="text-danger">*</em>
        </label>
        <input id="providerAccountField" v-model.trim="providerForm.providerAccountField" @input="$v.providerForm.providerAccountField.$touch()" v-mask="'##########################'" class="form-control" :class="{ 'is-invalid': $v.providerForm.providerAccountField.$error, 'is-valid': !$v.providerForm.providerAccountField.$invalid && !$v.providerForm.providerAccountField.$error }" type="text" name="providerAccountField" placeholder="Wpisz numer konta firmy" value required :disabled="disabledSwitch == 0" />
        <div v-if="$v.providerForm.providerAccountField.$error" class="invalid-feedback">
          <span class="d-block" v-if="$v.providerForm.providerAccountField.$error && !$v.providerForm.providerAccountField.required">Pole jest wymagane!</span>
          <span class="d-block" v-if="$v.providerForm.providerAccountField.$error && !$v.providerForm.providerAccountField.maxLength">Przekroczona dozwolona ilość znaków!</span>
          <span class="d-block" v-if="$v.providerForm.providerAccountField.$error && !$v.providerForm.providerAccountField.minLength">Zbyt mała ilość znaków!</span>
          <span class="d-block" v-if="$v.providerForm.providerAccountField.$error && !$v.providerForm.providerAccountField.numeric">Pole przyjmuje wyłącznie wartości liczbowe!</span>
        </div>
      </div>
      <div class="d-flex justify-content-start">
        <switches class="mt-2" v-model="providerForm.providerFormDataModified" @input="onChangeEventHandler($event)" :disabled="disabledSwitch == 1" type-bold="false" :color="disabledSwitch == 1 ? 'success' : 'primary'"></switches>
        <label class="mt-1 ml-3"><span class="mr-2">Zmień dane ręcznie:</span>
          <span v-if="providerForm.providerFormDataModified" class="badge badge-pill badge-soft-success font-size-14">TAK</span>
          <span v-else class="badge badge-pill badge-soft-danger font-size-14">NIE</span>
        </label>
      </div>
    </div>
  </div>
</template>
