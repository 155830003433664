// Typ przelewu
// Słownik: Zwykły / Split Payment (przy SP wymagany NIP dostawcy)
// lista wyboru
// VO: TypeOfTransfer

const typeOfTransferData = [
  {
    index: 1,
    name: "PZ",
    description: "Przelew zwykły",
    typeOfTransfer: "Zwykły",
    value: "Zwykły",
    text: "Przelew zwykły",
  },
  {
    index: 2,
    name: "SP",
    description: "Split payment",
    typeOfTransfer: "Split payment",
    value: "Split payment",
    text: "Split payment",
  },
];

export { typeOfTransferData };
